import axios from "axios";

export const AuthRequest = async (url, apiKey) => {
	return new Promise(async function (resolve, reject) {
		// Requisição
		axios
			.get(`${url}/tc-api/${apiKey}/event_essentials`)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				console.log(error);
				resolve(false);
			});
	});
};

export const ListRequest = async (url, apiKey, soldTickets) => {
	return new Promise(async function (resolve, reject) {
		// Requisição
		axios
			.get(`${url}/tc-api/${apiKey}/tickets_info/${soldTickets}/1`)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				console.log(error);
				resolve(false);
			});
	});
};

export const CheckInRequest = async (checksum) => {
	return new Promise(async function (resolve, reject) {
		const url = localStorage.getItem("Url");
		const apiKey = localStorage.getItem("ApiKey");
		axios
			.get(`${url}/tc-api/${apiKey}/check_in/${checksum}`)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				console.log(error);
				resolve(false);
			});
	});
};

export const CheckInListRequest = async (checksum) => {
	return new Promise(async function (resolve, reject) {
		const url = localStorage.getItem("Url");
		const apiKey = localStorage.getItem("ApiKey");
		axios
			.get(`${url}/tc-api/${apiKey}/ticket_checkins/${checksum}`)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				console.log(error);
				resolve(false);
			});
	});
};

export const ticketsQueueRequest = async (ticketsQueue) => {
	return new Promise(async function (resolve, reject) {
		const apiKey = localStorage.getItem("ApiKey");
		const url = localStorage.getItem("Url");
		axios({
			url: `${url}/wp-json/essencia/tickera/${apiKey}/check_in/`,
			data: ticketsQueue,
			method: "post",
		})
			.then((response) => {
				if (response.data.status === "pass") {
					resolve(true);
				} else {
					resolve(false);
				}
			})
			.catch((error) => {
				resolve(false);
			});
	});
};
