import React, { useState } from "react";

import { AuthController } from "../../controllers/TickeraController";
import ModalMessage from "../../components/ModalMessage";
import CircularProgress from "@material-ui/core/CircularProgress";

import EvFlowImg from "../../assets/logoevflow.png";

import "./style.css";

export default function Login() {
	const [url, setUrl] = useState("");
	const [apiKey, setApiKey] = useState("");
	const [loading, setLoading] = useState(false);

	const [modalMessage, setModalMessage] = useState("");
	const [modalOpen, setModalOpen] = useState(false);

	async function handleClick() {
		localStorage.removeItem("firebaseData");
		if (!navigator.onLine) {
			setModalMessage(
				"Verifique sua conexão e tente novamente mais tarde"
			);
			setModalOpen(false);
			setTimeout(() => {
				setModalOpen(true);
			}, 250);
			setInterval(() => {
				if (document.getElementById("modal-message-container")) {
					document.getElementById(
						"modal-message-container"
					).style.left = "47%";
				}
			}, 100);
		} else {
			if (!loading) {
				setLoading(true);
				let response;
				if (url.includes("https://") || url.includes("http://")) {
					response = await AuthController(url, apiKey, "/status");
				} else {
					response = await AuthController(
						`https://${url}`,
						apiKey,
						"/status"
					);
				}
				if (!response) {
					setModalMessage("Verifique seus dados e tente novamente");
					setLoading(false);
					setModalOpen(false);
					setTimeout(() => {
						setModalOpen(true);
					}, 250);
					setInterval(() => {
						if (
							document.getElementById("modal-message-container")
						) {
							document.getElementById(
								"modal-message-container"
							).style.left = "47%";
						}
					}, 100);
				}
			}
		}
	}

	return (
		<div id="label-container">
			<main>
				<img src={EvFlowImg} alt="EvFlowImg" />
			</main>
			<div>
				<label>Url do evFlow</label>
				<input
					placeholder="http://www.website.com/"
					type="text"
					value={url}
					onChange={(e) => setUrl(e.target.value)}
				/>
			</div>
			<div>
				<label>Código do evento</label>
				<input
					type="text"
					value={apiKey}
					onChange={(e) => setApiKey(e.target.value)}
				/>
			</div>
			<hr />
			<button type="button" onClick={handleClick}>
				{loading ? <CircularProgress /> : "Entrar"}
			</button>
			<ModalMessage
				type={"error"}
				message={modalMessage}
				open={modalOpen}
				successParams={[]}
			/>
		</div>
	);
}
