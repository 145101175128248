import React, { useState } from "react";

import { Offline, Online } from "react-detect-offline";
import HomeIcon from "@material-ui/icons/Home";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import CropFreeIcon from "@material-ui/icons/CropFree";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import OnlineLogo from "../../assets/wifi.svg";
import OfflineLogo from "../../assets/nowifi.svg";
import EvFlowImg from "../../assets/logoevflow.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";

import ModalMessage from "../ModalMessage";

import { AuthController } from "../../controllers/TickeraController";
import { pad } from "../../helpers/formatNumber";

import { Link, useLocation } from "react-router-dom";

import "./style.css";

export default function NavBar() {
	const history = useHistory();

	let { pathname } = useLocation();

	const [loading, setLoading] = useState(false);

	const [modalMessage, setModalMessage] = useState("");
	const [modalOpen, setModalOpen] = useState(false);

	async function handleSincBtn() {
		if (!loading) {
			setLoading(true);
			const response = await AuthController(
				localStorage.getItem("Url"),
				localStorage.getItem("ApiKey"),
				pathname
			);
			if (response) {
				setSyncDate();
			} else {
				setLoading(false);
				setModalMessage(
					"Não foi possível sincronizar! verifique sua conexão e tente novamente mais tarde"
				);
				setModalOpen(false);
				setTimeout(() => {
					setModalOpen(true);
				}, 250);
			}
		}
	}

	function setSyncDate() {
		let SyncDate = new Date();

		SyncDate = `${pad(SyncDate.getDate(), 2)}/${pad(
			SyncDate.getMonth() + 1,
			2
		)}/${SyncDate.getFullYear()} - ${pad(SyncDate.getHours(), 2)}:${pad(
			SyncDate.getMinutes(),
			2
		)}`;
		localStorage.setItem("SyncDate", SyncDate);
	}

	return (
		<>
			{pathname.split("/")[1] !== "" ? (
				<div id="navbar-container">
					<main>
						<img src={EvFlowImg} alt="EvFlowImg" />
					</main>
					<ul>
						<Link to="/status">
							<li
								className={
									pathname.split("/")[1] === "status"
										? "active"
										: ""
								}
							>
								<HomeIcon /> Home
							</li>
						</Link>
						<Link to="/lista">
							<li
								className={
									pathname.split("/")[1] === "lista" ||
									pathname.split("/")[1] === "detalhe"
										? "active"
										: ""
								}
							>
								<FormatListBulletedIcon />
								Lista
							</li>
						</Link>
						<Link to="/check-in">
							<li
								className={
									pathname.split("/")[1] === "check-in"
										? "active"
										: ""
								}
							>
								<CropFreeIcon /> Check-in
							</li>
						</Link>
						<Link to="/">
							<li>
								<ExitToAppIcon /> Sair
							</li>
						</Link>
					</ul>
					<div className={"sync-byn"}>
						<button type="button" onClick={handleSincBtn}>
							{loading ? (
								<>
									Sincronizando <CircularProgress />
								</>
							) : (
								"Sincronizar"
							)}
						</button>
						{localStorage.getItem("SyncDate") && (
							<small>
								Última sincronização em :{" "}
								{localStorage.getItem("SyncDate")}
							</small>
						)}
					</div>
					<Online>
						<footer>
							<img src={OnlineLogo} alt="OnlineLogo" />
						</footer>
					</Online>
					<Offline>
						<footer>
							<img src={OfflineLogo} alt="OfflineLogo" />
						</footer>
					</Offline>
					<img
						src={OfflineLogo}
						alt="OfflineLogo"
						style={{ display: "none" }}
					/>
					<ModalMessage
						type={"error"}
						message={modalMessage}
						open={modalOpen}
						successParams={[]}
					/>
				</div>
			) : null}
		</>
	);
}
