import firebase from "firebase";

const firebaseConfig = {
	apiKey: "AIzaSyDcpXYi7vzyRycSAY8EarmIKfcvYdE7SBM",
	authDomain: "evflow-7d0ce.firebaseapp.com",
	databaseURL: "https://evflow-7d0ce.firebaseio.com",
	projectId: "evflow-7d0ce",
	storageBucket: "evflow-7d0ce.appspot.com",
	messagingSenderId: "582292072414",
	appId: "1:582292072414:web:f8105a79b44e4a631a9f4f",
	measurementId: "G-MH236RZ2DM",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
