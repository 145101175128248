import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./pages/Login/index";

import Navbar from "./components/NavBar";

import Status from "./pages/Status/index";
import CheckIn from "./pages/CheckIn/index";
import TicketsList from "./pages/TicketsList/index";
import TicketsItem from "./pages/TicketsItem/index";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import alertImg from "./assets/alert.svg";
import errorImg from "./assets/cancel.svg";
import successImg from "./assets/checked.svg";
import infoImg from "./assets/info.svg";
import SearchBarImg from "./assets/barcode-scanner.svg";
import totalTicket from "./assets/ticketTotal.svg";
import ticketChecked from "./assets/ticket.svg";
import OfflineLogo from "./assets/nowifi.svg";
import { ticketsQueueController } from "./controllers/TickeraController";

import { Detector } from "react-detect-offline";

function Alert(props) {
	return (
		<MuiAlert
			elevation={6}
			variant="filled"
			{...props}
			closeText={"Fechar"}
		/>
	);
}

function Routes(props) {
	const [connection, setConnection] = useState(true);

	useEffect(() => {
		ticketsQueueConsume();
		window.addEventListener("online", () => ticketsQueueConsume());
	}, []);

	async function ticketsQueueConsume() {
		const ticketsQueue = JSON.parse(localStorage.getItem("ticketsQueue"));
		let response;
		if (ticketsQueue) {
			response = await ticketsQueueController(ticketsQueue);
		}
	}

	function handleClose() {
		setConnection(!connection);
	}

	return (
		<div className="root-container">
			<Router style={{ height: "100%" }}>
				<Navbar />
				<Detector
					onChange={(e) => setConnection(e)}
					render={({ online }) => (
						<Snackbar
							open={!connection}
							autoHideDuration={2500}
							onClose={handleClose}
						>
							<Alert onClose={handleClose} severity="error">
								Sem conexão com internet
							</Alert>
						</Snackbar>
					)}
				/>
				<Switch>
					{/* paginas */}
					<Route
						exact
						path="/"
						component={(props) => <Login {...props} />}
					/>

					<Route
						path="/status"
						component={(props) => <Status {...props} />}
					/>

					<Route
						path="/lista"
						component={(props) => <TicketsList {...props} />}
					/>

					<Route
						path="/detalhe/:id"
						component={(props) => <TicketsItem {...props} />}
					/>

					<Route
						path="/check-in"
						component={(props) => <CheckIn {...props} />}
					/>
				</Switch>
			</Router>
			{/* Carregamento das imagens para utilização Offline */}
			<img
				className={"preload-image"}
				src={alertImg}
				alt="Preload alert"
			/>
			<img
				className={"preload-image"}
				src={errorImg}
				alt="Preload error"
			/>
			<img
				className={"preload-image"}
				src={successImg}
				alt="Preload success"
			/>
			<img className={"preload-image"} src={infoImg} alt="Preload info" />
			<img
				className={"preload-image"}
				src={SearchBarImg}
				alt="Preload SearchBar"
			/>
			<img
				className={"preload-image"}
				src={totalTicket}
				alt="Preload totalTicket"
			/>
			<img
				className={"preload-image"}
				src={ticketChecked}
				alt="Preload ticketChecked"
			/>
			<img
				className={"preload-image"}
				src={OfflineLogo}
				alt="Preload OfflineLogo"
			/>
		</div>
	);
}

export default Routes;
