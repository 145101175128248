import React, { useState, useEffect } from "react";

import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import alertImg from "../../assets/alert.svg";
import errorImg from "../../assets/cancel.svg";
import successImg from "../../assets/checked.svg";
import infoImg from "../../assets/info.svg";
import "./style.css";

function getModalStyle() {
	return {
		top: `42%`,
		left: `57%`,
		transform: `translate(-38%, -50%)`,
	};
}

export default function ModalMessage(props) {
	useEffect(() => {
		setOpen(props.open);
		setIconImg();
		setTimeout(() => {
			if (document.getElementById("modal-message")) {
				document.getElementById(
					"modal-message"
				).innerHTML = document.getElementById(
					"modal-message"
				).innerText;
			}
		}, 200);
	}, [props.open]);

	function setIconImg() {
		switch (props.type) {
			case "error":
				setImgSrc(errorImg);
				break;
			case "success":
				setImgSrc(successImg);
				break;
			case "info":
				setImgSrc(infoImg);
				break;
			case "alert":
				setImgSrc(alertImg);
				break;
			default:
				setImgSrc(infoImg);
				break;
		}
	}

	const [modalStyle] = useState(getModalStyle);
	const [open, setOpen] = useState(false);
	const [imgSrc, setImgSrc] = useState(alertImg);

	const handleClose = () => {
		setOpen(false);
		if (props.type === "success" && navigator.onLine) {
			setInterval(() => {
				window.location.reload();
			}, 100);
		}
	};

	return (
		<Modal
			open={open}
			onClose={() => handleClose()}
			aria-labelledby="simple-modal-title"
			aria-describedby="modal-message"
		>
			<div style={modalStyle} id={"modal-message-container"}>
				<CloseIcon onClick={() => handleClose()} />
				<img src={imgSrc} alt="message-icon" />
				<p id={"modal-message"}>{props.message}</p>
				{props.successParams.length !== 0 && (
					<ul>
						<li>
							<p>ID</p>
							<span>{props.successParams[0]}</span>
						</li>
						<li>
							<p>Nome</p>
							<span>{props.successParams[1]}</span>
						</li>
						<li>
							<p>Comprador</p>
							<span>{props.successParams[2]}</span>
						</li>
						<li>
							<p>Tipo</p>
							<span>{props.successParams[3]}</span>
						</li>
					</ul>
				)}
			</div>
		</Modal>
	);
}
