import React, { useState, useEffect } from "react";

import totalTicket from "../../assets/ticketTotal.svg";
import ticketChecked from "../../assets/ticket.svg";

import "./style.css";

export default function Status() {
	useEffect(() => {
		try {
			setInterval(() => {
				getStatusInfo();
			}, 500);
		} catch (error) {
			setTimeout(() => {
				getStatusInfo();
			}, 500);
		}
	}, []);

	function getStatusInfo() {
		try {
			const firebaseData = JSON.parse(
				localStorage.getItem("firebaseData")
			);
			setCheckedTickets(firebaseData.checados);
			setSoldTickets(firebaseData.vendidos);
		} catch (error) {}
	}

	const [soldTickets, setSoldTickets] = useState("");
	const [checkedTickets, setCheckedTickets] = useState("");

	return (
		<div id="status-container">
			<div className="header">
				<h2>Vendidos</h2>
				<h2>Checados</h2>
			</div>
			<div className="content">
				<div>
					<label>{soldTickets}</label>
					<img src={totalTicket} alt="totalTicket" />
				</div>
				<div>
					<label>{checkedTickets}</label>
					<img src={ticketChecked} alt="ticketChecked" />
				</div>
			</div>
		</div>
	);
}
