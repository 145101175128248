import firebase from "../firebase";

import {
	AuthRequest,
	ListRequest,
	CheckInRequest,
	CheckInListRequest,
	ticketsQueueRequest,
} from "../services/TickeraService";

import { pad } from "../helpers/formatNumber";

export const AuthController = async (url, apiKey, path) => {
	const response = await AuthRequest(url, apiKey);
	if (response.data) {
		await syncFirebaseEventsData(response, apiKey);
		return await ListController(url, apiKey, response, path);
	} else {
		return response.data;
	}
};

export const ListController = async (url, apiKey, request, path) => {
	const { sold_tickets } = request.data;
	const response = await ListRequest(url, apiKey, sold_tickets);
	if (response) {
		localStorage.setItem("Url", url);
		localStorage.setItem("ApiKey", apiKey);
		await syncFirebaseTicketsData(response, apiKey, path);
		return response.data;
	} else {
		return false;
	}
};

export const CheckInController = async (checksum) => {
	const response = await CheckInRequest(checksum);
	if (response) {
		return response.data;
	} else {
		return false;
	}
};

export const CheckInListController = async (checksum) => {
	const response = await CheckInListRequest(checksum);
	if (response) {
		return response.data;
	} else {
		return false;
	}
};

async function syncFirebaseEventsData(response, apiKey) {
	const { data } = response;

	const event = {
		nmevento: data.event_name,
		dtevento: data.event_date_time,
		checados: data.checked_tickets,
		vendidos: data.sold_tickets,
	};

	firebase
		.database()
		.ref(`${apiKey}/`)
		.update(event)
		.then((data) => {
			console.log("Evento Sincronizado com sucesso");
		})
		.catch((error) => {
			console.log(error);
		});

	saveRemoteData(apiKey);
}

async function syncFirebaseTicketsData(response, apiKey, path) {
	const { data } = response;
	let ticketsDataArray = { tickets: {} };
	/** Salva os dados dos tickets dentro do evento */
	await Object.values(data).map(async (ticket, cont) => {
		const { data } = ticket;
		if (data) {
			const Ticketsdata = {
				nm: data.buyer_first + " " + data.buyer_last,
				dtticket: data.payment_date,
				idticket: data.checksum,
				emailcomprador: data.custom_fields[2][1],
				tpticket: data.custom_fields[0][1],
				comprador: data.custom_fields[1][1],
				dtcheck: data.date_checked,
				checkin: 0,
				cargas: data.allowed_checkins,
				user: data.user_login,
			};

			ticketsDataArray[data.checksum] = Ticketsdata;
		}
	});
	firebase
		.database()
		.ref(`${apiKey}/tickets/`)
		.update(ticketsDataArray)
		.then(async () => {
			console.log("Tickets Sincronizados com sucesso");
			if (
				document.location.href ===
				document.location.origin + "/#" + path
			) {
				window.location.reload(false);
			} else {
				document.location.href = document.location.origin + "/#" + path;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	saveRemoteData(apiKey);
}

async function syncFirebaseTicketStatusData(apiKey, checksum, isLast, path) {
	const response = await CheckInListController(checksum);

	const dtcheck = {
		dtcheck: response,
	};

	saveRemoteData(apiKey);

	firebase
		.database()
		.ref(`${apiKey}/tickets/${checksum}/`)
		.update(dtcheck)
		.then(() => {
			console.log("Lista de Status do Ticket Sincronizado com sucesso");
		})
		.catch((error) => {
			console.log(error);
		});
}

async function saveRemoteData(apiKey) {
	var ticketData = firebase.database().ref(`${apiKey}/`);
	ticketData.on("value", function (snapshot) {
		localStorage.setItem("firebaseData", JSON.stringify(snapshot.val()));
		setSyncDate();
	});
}

function setSyncDate() {
	let SyncDate = new Date();

	SyncDate = `${pad(SyncDate.getDate(), 2)}/${pad(
		SyncDate.getMonth() + 1,
		2
	)}/${SyncDate.getFullYear()} - ${pad(SyncDate.getHours(), 2)}:${pad(
		SyncDate.getMinutes(),
		2
	)}`;
	localStorage.setItem("SyncDate", SyncDate);
}

export async function ticketsQueueController(ticketsQueue) {
	const response = await ticketsQueueRequest(ticketsQueue);
	if (response === true) {
		localStorage.removeItem("ticketsQueue");
		return true;
	} else {
		ticketsQueueController(ticketsQueue);
	}
}
