import React, { useState } from "react";

import SearchBar from "../../components/SearchBar";
import ModalMessage from "../../components/ModalMessage";
import SearchBarImg from "../../assets/barcode-scanner.svg";
import ClearIcon from "@material-ui/icons/Clear";
import { checkInTicket } from "../../helpers/checkinHelper";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./style.css";

export default function CheckIn() {
	const [ticket, setTicket] = useState("");
	const [loading, setLoading] = useState(false);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState("error");
	const [modalMessage, setModalMessage] = useState(
		"Ticket inválido, verifique se já foi utilizado ou se está na validade!"
	);
	const [successParams, setSuccessParams] = useState([]);

	async function handleValidateBtn() {
		if (!loading) {
			setLoading(true);
			const result = await checkInTicket(ticket);

			switch (result) {
				case "404":
					setSuccessParams([]);
					setModalType("error");
					setModalMessage("Ticket não encontrado!");
					break;
				case "400":
					setSuccessParams([]);
					setModalType("error");
					localStorage.getItem("InsufficientPermissions") === "true"
						? setModalMessage(
								"Permissões insuficientes. Esta chave de API não pode fazer check-in neste tíquete."
						  )
						: setModalMessage(
								"Ticket inválido, verifique se já foi utilizado ou se está na validade!"
						  );
					break;
				case "200":
					const { tickets } = JSON.parse(
						localStorage.getItem("firebaseData")
					);
					try {
						setSuccessParams([
							tickets[ticket].idticket,
							tickets[ticket].nm,
							tickets[ticket].user +
								" - " +
								tickets[ticket].comprador,
							tickets[ticket].tpticket,
						]);
						setModalType("success");
						setModalMessage(`Dados do Ticket`);
					} catch (error) {
						setSuccessParams([]);
						setModalType("error");
						setModalMessage(
							"Ticket inválido, verifique se já foi utilizado ou se está na validade!"
						);
					}

					break;
				default:
					setSuccessParams([]);
					setModalType("error");
					setModalMessage(
						"Ticket inválido, verifique se já foi utilizado ou se está na validade!"
					);
					break;
			}
			setModalOpen(false);
			setTimeout(() => {
				setModalOpen(true);
				setLoading(false);
			}, 250);
		}
	}

	function clearText() {
		setTicket("");
	}

	return (
		<div id="check-in-container">
			<SearchBar title={"CHECK-IN"} />
			<div className="content">
				<img src={SearchBarImg} alt="scanner" />
				<h1>Check-in</h1>
				<div className={"input-block"}>
					<input
						type="text"
						value={ticket}
						onChange={(e) => setTicket(e.target.value)}
					/>
					{ticket.length !== 0 && (
						<ClearIcon
							className={"clear-btn"}
							onClick={clearText}
						/>
					)}
				</div>
				<label>Digite o Código do ticket</label>
				<button type="button" onClick={handleValidateBtn}>
					{loading ? <CircularProgress /> : "Validar"}
				</button>
			</div>
			<ModalMessage
				type={modalType}
				message={modalMessage}
				open={modalOpen}
				successParams={successParams}
			/>
		</div>
	);
}
