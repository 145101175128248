import React, { useState, useEffect } from "react";

import SearchBar from "../../components/SearchBar";
import { getMonthNumber } from "../../helpers/date";

import { Link } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useSelector } from "react-redux";
import { pad } from "../../helpers/formatNumber";

import "./style.css";

export default function TicketsList() {
	const searchText = useSelector((state) => state);

	useEffect(() => {
		const { tickets } = JSON.parse(localStorage.getItem("firebaseData"));

		let TicketsData = tickets;

		let filterTickets = [];
		if (searchText !== "") {
			let searchTextFormated = searchText
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.toUpperCase();
			Object.keys(TicketsData).forEach(function (key) {
				let idticket = TicketsData[key].idticket
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase();

				let name = TicketsData[key].nm
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase();

				let comprador = TicketsData[key].comprador
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase();

				if (
					idticket.includes(searchTextFormated) ||
					name.includes(searchTextFormated) ||
					comprador.includes(searchTextFormated)
				) {
					filterTickets.push(TicketsData[key]);
				}
			});
		} else {
			try {
				Object.keys(TicketsData).forEach(function (key) {
					filterTickets.push(TicketsData[key]);
				});
			} catch (error) {
				filterTickets = [];
			}
		}
		filterTickets.sort(compareItens);
		setTicketsData(filterTickets);
	}, [searchText]);

	function compareItens(firstItem, NextItem) {
		let dateFirst = firstItem.dtticket.split(" ");
		dateFirst = `${dateFirst[4]}-${getMonthNumber(dateFirst[2])}-${pad(
			dateFirst[0],
			2
		)}T${dateFirst[6]}:00`;
		dateFirst = new Date(dateFirst);
		if (dateFirst == "Invalid Date") {
			dateFirst = firstItem.dtticket.split(" ");
			dateFirst = `${dateFirst[0].substring(
				6,
				dateFirst[0].length
			)}-${dateFirst[0].substring(
				3,
				dateFirst[0].length - 5
			)}-${dateFirst[0].substring(0, 2)}T${dateFirst[2]}:00`;
			dateFirst = new Date(dateFirst);
		}

		let dateNext = NextItem.dtticket.split(" ");
		dateNext = `${dateNext[4]}-${getMonthNumber(dateNext[2])}-${pad(
			dateNext[0],
			2
		)}T${dateNext[6]}:00`;
		dateNext = new Date(dateNext);

		if (dateNext == "Invalid Date") {
			dateNext = NextItem.dtticket.split(" ");
			dateNext = `${dateNext[0].substring(
				6,
				dateNext[0].length
			)}-${dateNext[0].substring(
				3,
				dateNext[0].length - 5
			)}-${dateNext[0].substring(0, 2)}T${dateNext[2]}:00`;
			dateNext = new Date(dateNext);
		}

		return dateNext.getTime() - dateFirst.getTime();
	}

	const [ticketsData, setTicketsData] = useState([]);

	return (
		<div id="list-container">
			<SearchBar title={"Lista"} />
			<ul>
				{ticketsData.map((ticket, count) => (
					<Link key={count} to={`detalhe/${ticket.idticket}`}>
						<li>
							<div className={"info-container"}>
								<label>{ticket.nm}</label>
								<div>
									<label className="label-id">
										ID: <strong>{ticket.idticket}</strong>{" "}
									</label>
									<label>
										Vendido em:{" "}
										<strong>{ticket.dtticket}</strong>{" "}
									</label>
								</div>
							</div>
							<div className={"button-icon"}>
								<ArrowForwardIcon />
							</div>
						</li>
					</Link>
				))}
				{ticketsData.length === 0 && <p>Nenhum Ticket encontrado</p>}
			</ul>
		</div>
	);
}
