export function getMonthNumber(month) {
	switch (month) {
		case "janeiro":
			return "01";
		case "fevereiro":
			return "02";
		case "março":
			return "03";
		case "abril":
			return "04";
		case "maio":
			return "05";
		case "junho":
			return "06";
		case "julho":
			return "07";
		case "agosto":
			return "08";
		case "setembro":
			return "09";
		case "outubro":
			return "10";
		case "novembro":
			return "11";
		case "dezembro":
			return "12";
		default:
			return "01";
	}
}

export function getMonthName(month) {
	let monthValue = month + 1;
	switch (monthValue) {
		case 1:
			return "janeiro";
		case 2:
			return "fevereiro";
		case 3:
			return "março";
		case 4:
			return "abril";
		case 5:
			return "maio";
		case 6:
			return "junho";
		case 7:
			return "julho";
		case 8:
			return "agosto";
		case 9:
			return "setembro";
		case 10:
			return "outubro";
		case 11:
			return "novembro";
		case 12:
			return "dezembro";
		default:
			return "janeiro";
	}
}
