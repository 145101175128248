import {
	CheckInController,
	AuthController,
} from "../controllers/TickeraController";

export const checkInTicket = async (checksum) => {
	localStorage.removeItem("InsufficientPermissions");
	const { tickets } = JSON.parse(localStorage.getItem("firebaseData"));
	if (await makeCheckInTicket(checksum, tickets[checksum], tickets)) {
		return "200";
	} else {
		return "400";
	}
};

function checkIfTicketExist(tickets, checksum) {
	return tickets[checksum] !== undefined ? true : false;
}

function checkTicketLoad(ticket) {
	return ticket.cargas > 0 ? true : false;
}

async function makeCheckInTicket(checksum, ticket, tickets) {
	const response = await CheckInController(checksum);
	if (response) {
		if (
			response ===
				"Insufficient permissions. This API key cannot check in this ticket." ||
			response ===
				"Permissões insuficientes. Esta chave de API não pode fazer check-in neste tíquete."
		) {
			localStorage.setItem("InsufficientPermissions", "true");
		} else {
			const firebaseData = JSON.parse(
				localStorage.getItem("firebaseData")
			);
			const Ticketsdata = {
				cargas: 0,
				nm: response.name,
				dtticket: response.payment_date,
				idticket: response.checksum,
				emailcomprador: response.custom_fields[2][1],
				tpticket: response.custom_fields[0][1],
				comprador: response.custom_fields[1][1],
				dtcheck: "",
				checkin: 0,
				user: response.user_login,
			};
			firebaseData.tickets[response.checksum] = Ticketsdata;
			localStorage.setItem("firebaseData", JSON.stringify(firebaseData));
			if (response.status) {
				return true;
			} else {
				return false;
			}
		}
	} else {
		if (!checkIfTicketExist(tickets, checksum)) return "404";
		if (!checkTicketLoad(ticket)) return false;
		// Realização de check-in offline
		// -- Alteração do banco local
		const firebaseData = JSON.parse(localStorage.getItem("firebaseData"));
		ticket.cargas = ticket.cargas - 1;
		ticket.checkin = ticket.checkin + 1;
		firebaseData.tickets[checksum] = ticket;
		localStorage.setItem("firebaseData", JSON.stringify(firebaseData));

		// -- Adicionado ticket para a fila de check-in que será feita quando tiver conexão
		let ticketsQueue = JSON.parse(localStorage.getItem("ticketsQueue"));
		if (ticketsQueue === null) {
			ticketsQueue = [
				{
					id: ticket.idticket,
					tm: Math.floor(Date.now() / 1000),
				},
			];
		} else {
			ticketsQueue.push({
				id: ticket.idticket,
				tm: Math.floor(Date.now() / 1000),
			});
		}
		localStorage.setItem("ticketsQueue", JSON.stringify(ticketsQueue));

		return true;
	}
}
