import React, { useState, useEffect } from "react";

import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./style.css";

export default function SearchBar(props) {
	const searchText = useSelector((state) => state);
	const dispatch = useDispatch();
	const history = useHistory();

	const [searchTextValue, setSearchTextValue] = useState(searchText);

	useEffect(() => {
		setSearchTextValue(searchText);
	}, [searchText]);

	function searchTicket() {
		dispatch({
			type: "SEARCH_ITEM",
			searchText: searchTextValue,
		});
		history.push("/lista");
	}

	function clearText() {
		setSearchTextValue("");
		dispatch({
			type: "SEARCH_ITEM",
			searchText: "",
		});
	}

	return (
		<div id="search-bar-container">
			<label>{props.title}</label>
			<div>
				<input
					placeholder={"Busque aqui"}
					type="text"
					value={searchTextValue}
					onChange={(e) => setSearchTextValue(e.target.value)}
					onKeyDown={(e) => e.key === "Enter" && searchTicket()}
				/>
				<SearchIcon onClick={searchTicket} />
				{searchTextValue.length !== 0 && (
					<ClearIcon className={"clear-btn"} onClick={clearText} />
				)}
			</div>
		</div>
	);
}
