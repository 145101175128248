import React, { useState, useEffect } from "react";

import SearchBar from "../../components/SearchBar";
import { checkInTicket } from "../../helpers/checkinHelper";
import ModalMessage from "../../components/ModalMessage";
import { CheckInListController } from "../../controllers/TickeraController";
import { Link } from "react-router-dom";
import { getMonthName } from "../../helpers/date";
import { pad } from "../../helpers/formatNumber";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./style.css";

export default function TicketsItem(props) {
	const [name, setName] = useState("");
	const [checksum, setChecksum] = useState("");
	const [date, setDate] = useState("");
	const [ticketType, setTicketType] = useState("");
	const [buyerName, setBuyerName] = useState("");
	const [username, setUsername] = useState("");
	const [buyerEmail, setBuyerEmail] = useState("");
	const [ticketsStatus, setTicketsStatus] = useState([]);
	const [loading, setLoading] = useState(false);

	//modal
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState("error");
	const [modalMessage, setModalMessage] = useState(
		"Ticket inválido, verifique se já foi utilizado ou se está na validade!"
	);
	const [successParams, setSuccessParams] = useState([]);

	useEffect(() => {
		loadTicket();
	}, []);

	function loadTicket() {
		const ticketChecksum = props.match.params.id;
		const { tickets } = JSON.parse(localStorage.getItem("firebaseData"));

		setTicketInfo(tickets[ticketChecksum]);
		checkCheckInList(ticketChecksum);
	}

	async function checkCheckInList(ticketChecksum) {
		const response = await CheckInListController(ticketChecksum);
		let ticketsStatusArray = [];
		if (response) {
			response.forEach((element) => {
				ticketsStatusArray.push(element);
			});
		}
		setTicketsStatus(ticketsStatusArray);

		if (ticketsStatusArray.length === 0) {
			checkTicketsQueue(ticketChecksum);
		}
	}

	function setTicketInfo(listData) {
		setName(listData.nm);
		setChecksum(listData.idticket);
		setDate(listData.dtticket);
		setTicketType(listData.tpticket);
		setBuyerName(listData.comprador);
		setBuyerEmail(listData.emailcomprador);
		setUsername(listData.user);
	}

	async function handleValidateBtn() {
		if (!loading) {
			setLoading(true);
			const ticketChecksum = props.match.params.id;
			const result = await checkInTicket(ticketChecksum);

			switch (result) {
				case "404":
					setSuccessParams([]);
					setModalType("error");
					setModalMessage("Ticket não encontrado!");
					break;
				case "400":
					setSuccessParams([]);
					setModalType("error");
					localStorage.getItem("InsufficientPermissions") === "true"
						? setModalMessage(
								"Permissões insuficientes. Esta chave de API não pode fazer check-in neste tíquete."
						  )
						: setModalMessage(
								"Ticket inválido, verifique se já foi utilizado ou se está na validade!"
						  );
					break;
				case "200":
					const { tickets } = JSON.parse(
						localStorage.getItem("firebaseData")
					);
					setSuccessParams([
						tickets[ticketChecksum].idticket,
						tickets[ticketChecksum].nm,
						tickets[ticketChecksum].user +
							" - " +
							tickets[ticketChecksum].comprador,
						tickets[ticketChecksum].tpticket,
					]);
					setModalType("success");
					setModalMessage(`Dados do Ticket`);
					checkTicketsQueue(tickets[ticketChecksum].idticket);
					break;
				default:
					setSuccessParams([]);
					setModalType("error");
					setModalMessage(
						"Ticket inválido, verifique se já foi utilizado ou se está na validade!"
					);
					break;
			}
			setModalOpen(false);
			setTimeout(() => {
				setModalOpen(true);
				setLoading(false);
			}, 250);
		}
	}

	function checkTicketsQueue(checksum) {
		const ticketsQueue = JSON.parse(localStorage.getItem("ticketsQueue"));
		if (ticketsQueue) {
			let ticketsStatusArray = [];
			ticketsQueue.forEach((element) => {
				if (element.id === checksum) {
					let letElementDate = new Date(element.tm * 1000);
					let formatedDate = `${letElementDate.getDay()} de ${getMonthName(
						letElementDate.getMonth()
					)} de ${letElementDate.getFullYear()} - ${pad(
						letElementDate.getHours(),
						2
					)}:${pad(letElementDate.getMinutes(), 2)} `;
					let ticketJSON = {
						data: { date_checked: formatedDate, status: "Pass" },
					};
					ticketsStatusArray.push(ticketJSON);
				}
			});
			setTicketsStatus(ticketsStatusArray);
		}
	}

	return (
		<div id="item-container">
			<SearchBar title={"Lista"} />
			<Link to={"../lista"}>
				<ArrowBackIcon />
			</Link>
			<h1>{name}</h1>
			<p>
				ID : <strong> {checksum} </strong>
			</p>
			<p>
				Vendido em : <strong> {date}</strong>
			</p>
			<hr />
			<p>
				Tipo do Ticket : <strong> {ticketType} </strong>
			</p>
			<hr />
			<p>
				Nome do Comprador :{" "}
				<strong>
					{" "}
					{username} - {buyerName}{" "}
				</strong>
			</p>
			<hr />
			<p>
				Email do Comprador : <strong> {buyerEmail} </strong>
			</p>
			<hr />
			{ticketsStatus.length > 0 ? (
				<>
					<h2>CHECK-INS</h2>
					<ul>
						{ticketsStatus.map((ticket, cont) => (
							<li key={cont}>
								{ticket.data.date_checked +
									" - Status: " +
									(ticket.data.status === "Pass"
										? "Checado"
										: "Falhou")}
							</li>
						))}
					</ul>
				</>
			) : null}

			<footer>
				<button onClick={handleValidateBtn} type="button">
					{loading ? <CircularProgress /> : "FAZER CHECK-IN"}
				</button>
			</footer>
			<ModalMessage
				type={modalType}
				message={modalMessage}
				open={modalOpen}
				successParams={successParams}
			/>
		</div>
	);
}
